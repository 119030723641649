import { createGlobalStyle } from "styled-components";
import GothamBlackWoff from "./fonts/Gotham-Black.woff"
import GothamBlackWoff2 from "./fonts/Gotham-Black.woff2"

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'Gotham';
    src: url(${GothamBlackWoff2}) format('woff2'),
        url(${GothamBlackWoff}) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

*,*::before,*::after{
    padding:0;
    margin:0;
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* border:1px solid red; */
}

html{
    font-size:62.5%; //HAPPY REMS
}

body{
    font-size:1.6rem; //HAPPY REMS FIX
    font-family: 'Roboto', sans-serif;
    font-weight:400;
}
`;

export default GlobalStyle;