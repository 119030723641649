import styled from "styled-components"
import { wlapy, wlapson} from "../../data";
import WlapyListItem from "./WlapyListItem";
import WlapsonListItem from "./WlapsonListItem";
import wrap_hero from "../../images/wrap_hero.png"
import wlapson_hero from "../../images/wlapson.png"

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:0 15px;
padding-bottom:15px;
padding-top:15px;
background-color: #f0f0f0;
`
const Image = styled.img`
width:100%;
padding:5px;
`
const Jumbotron = styled.img`
width:100%;
border-radius: 5px;
-webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
-moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
`

// const HyperLink = styled.a`
// outline:0;
// border:0;
// text-decoration:none ;
// `;

const Badge = styled.span`
    display:inline-flex;
    justify-content: center;
    align-items: center;
    font-size:1.8rem ;
    padding:0.2em 0.6em;
    border-radius:2.4rem ;
    margin:10px 0;
    font-family:'Gotham', sans-serif;
    color:#62D667;
    background-color:#075117;
    text-transform:uppercase ;
    cursor: pointer;
    text-decoration: none;
`;

const WlapyList = () => {
    return (
        <Wrapper>
            <Jumbotron src={wrap_hero}/>
            {wlapy.map(burger=><WlapyListItem burger={burger} key={burger.name}/>)}
            {/* <Jumbotron src={wlapson_hero}/>
            {wlapson.map(burger=><WlapsonListItem burger={burger} key={burger.name}/>)} */}
            
        </Wrapper>
    );
}

export default WlapyList;