import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  background-color: black;
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const HyperLink = styled.a`
  display: inline-block;
  color: white;
  font-family: Gotham, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
`;

const Copy = styled.span`
  color: gray;
  font-size: 12px;
  font-weight: 700;
`;

const Footer = () => {
  return (
    <Wrapper>
      <HyperLink
        href="https://pasibus.blob.core.windows.net/media/4476/tabela-alergen%C3%B3w-ca%C5%82e-menu-1-03-23.pdf"
        target="_blank"
      >
        Table of Alergens
      </HyperLink>
      <HyperLink
        href="https://pasibus.blob.core.windows.net/media/6616/tabela-alergen%C3%B3w-i-warto%C5%9Bci-od%C5%BCywczych-lto-3-chili-con-carne-24-ang.pdf"
        target="_blank"
      >
        Table of Alergens of the Chili&nbsp;Con&nbsp;Carne
      </HyperLink>
      <HyperLink
        href="https://pasibus.blob.core.windows.net/media/6781/tabela-alergen%C3%B3w-i-warto%C5%9Bci-od%C5%BCywczych-burger-miesi%C4%85ca-czerwiec-24-ang.pdf"
        target="_blank"
      >
        Burger of the Month Alergens
      </HyperLink>
      <Copy>Copyrights @Pasibus Sp. z o. o.</Copy>
    </Wrapper>
  );
};

export default Footer;
