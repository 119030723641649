import styled from "styled-components"
import { lemoIce } from "../../data";
import LemoIceListItem from "./LemoIceListItem";
import icepasino from "../../images/icepasino.jpg";

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:0 15px;
padding-bottom:10px;
background-color: #f0f0f0;
padding-top:15px;
`

const Jumbotron = styled.img`
width:100%;
border-radius: 5px;
-webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
-moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
margin-bottom:20px;
margin-top:15px;
`

const SniadankoList = () => {
    return (
        <Wrapper>
            {/* <Jumbotron src={icepasino}/> */}
            {lemoIce.map(pasik=><LemoIceListItem burger={pasik} key={pasik.name}/>)}
        </Wrapper>
    );
}

export default SniadankoList;