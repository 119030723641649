import styled from "styled-components"
import { dodatki } from "../../data";
import DodatkiListItem from "./DodatkiListItem";
import kulki from "../../images/kulki.png"

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:15px;
padding-bottom:15px;
background-color: #f0f0f0;

`

const Jumbotron = styled.img`
width:100%;
border-radius: 5px;
-webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
-moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
margin-bottom:20px;
`
const HyperLink = styled.a`
outline:0;
border:0;
text-decoration:none ;
`;

const DodatkiList = () => {
    return (
        <Wrapper>
            <Jumbotron src={kulki}/>
            {dodatki.map(dodatek=><DodatkiListItem burger={dodatek} key={dodatek.name}/>)}
        </Wrapper>
    );
}

export default DodatkiList;