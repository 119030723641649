
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import ZestawyList from "./ZestawyList"
import Footer from "../Footer/Footer";
import BannerLink from "../BannerLink/BannerLink";


const Zestawy = () => {

    return (
        <>
        <Heading>sets</Heading>
        <MenuScroll highlight="zestawy"/>
        <BannerLink link="/doladowanie">+ boost your burger</BannerLink>
        
        <ZestawyList/>
        <Footer/>
        </>
    );
};

export default Zestawy;