import styled from "styled-components"
import { kawa } from "../../data";
import KawaListItem from "./KawaListItem";

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:0 15px;
padding-bottom:15px;
background-color: #f0f0f0;

`

const KawaList = () => {
    return (
        <Wrapper>
            {kawa.map(pasik=><KawaListItem burger={pasik} key={pasik.name}/>)}
        </Wrapper>
    );
}

export default KawaList;