import styled from "styled-components"
import { salatki } from "../../data";
import SalatkiListItem from "./SalatkiListItem";
import salatkiimg from "../../images/salatki.jpg"

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:0 15px;
padding-bottom:15px;
background-color: #f0f0f0;
`
const Jumbotron = styled.img`
width:100%;
border-radius: 5px;
-webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
-moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
margin-bottom:20px;
margin-top:15px;
`

const SalatkiList = () => {
    return (
        <Wrapper>
           <Jumbotron src={salatkiimg}/>
            {salatki.map(salatka=><SalatkiListItem burger={salatka} key={salatka.name}/>)}
        </Wrapper>
    );
}

export default SalatkiList;