
import BannerLink from "../BannerLink/BannerLink";
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import Footer from "../Footer/Footer";
import SalatkiList from "./SalatkiList";

const Salatki = () => {
    return (
        <>
        <Heading>Salads</Heading>
        <MenuScroll highlight="salatki"/>
        <BannerLink link="/dodatki">+ add extras</BannerLink>
        <SalatkiList/>
        <Footer/>
        </>
    );
};

export default Salatki;