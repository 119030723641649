import styled from "styled-components";

const Heading = styled.h1`
  color: black;
  font-family: "Gotham", sans-serif;
  text-transform: uppercase;
  font-size: 4rem;
  margin: 0 15px;
  min-height: 50px;
  margin-top: 25px;
`;

export default Heading;
