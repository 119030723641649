import styled from "styled-components";

const Multiline = styled.span`
    display:inline-flex;
    justify-content: center;
    align-items: center;
    font-size:1.5rem ;
    padding:0.08em 0.3em;
    border-radius:.4rem ;
    font-family:'Gotham', sans-serif;
    color:white;
    background-color:black;
    text-transform:uppercase ;
    margin:5px;
    cursor: pointer;
`;

export default Multiline;