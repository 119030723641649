export const burgery = [
  // {
  //   name: "MANGOCZI",
  //   ingredients: [
  //     "100% beef/chicken/vege/cheese cutlet",
  //     "mayo-sriracha",
  //     "baby spinach",
  //     "cheddar cheese",
  //     "mango-chili sauce",
  //     "red onion",
  //     "cilantro"
  //   ],
  //   img: "https://pasibus.blob.core.windows.net/media/6782/burger-of-monthczerwiec.png?width=500&mode=max&animationprocessmode=first",
  // },
  {
    name: "Standard",
    ingredients: [
      "100% beef",
      "white sauce",
      "iceberg lettuce",
      "cheddar cheese",
      "tomato",
      "pickled cucumber",
      "onion",
    ],
    priceL: "20.99",
    priceXL: "22.99",
    img: "https://pasibus.blob.core.windows.net/media/3342/standard_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Bebek Junior",
    ingredients: [
      "100% beef",
      "pink sauce",
      "iceberg lettuce",
      "BBQ sauce",
      "cheddar cheese",
      "onion",
      "grilled bacon",
    ],
    priceL: "21.99",
    priceXL: "23.99",
    img: "https://pasibus.blob.core.windows.net/media/3337/bebek-jr_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Włoski Pastuch",
    ingredients: [
      "100% beef",
      "white sauce",
      "arugula",
      "long-ripening cheese",
      "tomato-basil sauce",
      "grilled bacon",
    ],
    priceL: "22.99",
    priceXL: "25.99",
    img: "https://pasibus.blob.core.windows.net/media/3343/w%C5%82oski-pastuch_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Gonzales",
    ingredients: [
      "100% beef",
      "pink sauce",
      "arugula",
      "red curry paste",
      "cheddar cheese",
      "jalapeño",
      "nachos",
    ],
    priceL: "22.99",
    priceXL: "24.99",
    hot: 2,
    img: "https://pasibus.blob.core.windows.net/media/3735/gonzales_xl-1.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "Red Hot",
  //   ingredients: [
  //     "100% beef",
  //     "jalapeño-mayo sauce",
  //     "iceberg lettuce",
  //     "red hot sauce with pepperoncino",
  //     "fresh chilli",
  //   ],
  //   priceL: "21.99",
  //   priceXL: "23.99",
  //   hot: 3,
  //   img: "https://pasibus.blob.core.windows.net/media/3736/redhot_xl-1.png?width=500&mode=max&animationprocessmode=first",
  // },
  // {
  //   name: "Chorizard",
  //   ingredients: [
  //     "100% beef",
  //     "pink sauce",
  //     "arugula",
  //     "dried tomato and cranberry paste",
  //     "cheddar cheese",
  //     "grilled chorizo",
  //     "grilled mushrooms",
  //   ],
  //   priceL: "25.99",
  //   priceXL: "28.99",
  //   hot: 1,
  //   img: "https://pasibus.blob.core.windows.net/media/3734/chorizard_xl-1.png?width=500&mode=max&animationprocessmode=first",
  // },
  {
    name: "Awokadus",
    ingredients: [
      "100% beef",
      "pink sauce",
      "lambs lettuce",
      "guacamole",
      "honey-mustard sauce with nuts",
      "cheddar cheese",
      "grilled bacon",
    ],
    priceL: "24.99",
    priceXL: "27.99",
    img: "https://pasibus.blob.core.windows.net/media/3338/awokadus_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Biały Wilk",
    ingredients: [
      "100% beef",
      "white sauce",
      "iceberg lettuce",
      "stewed onion",
      "double grilled bacon",
      "thyme",
    ],
    priceL: "22.99",
    priceXL: "25.99",
    img: "https://pasibus.blob.core.windows.net/media/3339/bia%C5%82y-wilk_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Poczwórny smash burger",
    ingredients: [
      "Quadruple smashed beef",
      "Quadruple cheddar cheese",
      "Pink sauce",
      "Stewed onion",
      "Cucumber relish",
      "BBQ sauce",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6343/double-double-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const vegan = [
  // {
  //   name: "Standard Vegan",
  //   ingredients: [
  //     "Original vegetable coutlet",
  //     "vegan mayonnaise",
  //     "iceberg lettuce",
  //     "vegan cheddar cheese",
  //     "tomato",
  //     "pickled cucumber",
  //     "onion",
  //   ],
  //   priceXL: "25.99",
  //   img: "https://pasibus.blob.core.windows.net/media/3354/standard-vegan_xl.png?width=500&mode=max&animationprocessmode=first",
  // },
  // {
  //   name: "Cebulion Vegan",
  //   ingredients: [
  //     "Original vegetable coutlet",
  //     "vegan mayonnaise",
  //     "arugula",
  //     "stewed onion",
  //     "pickled cucumber",
  //     "BBQ sauce",
  //   ],
  //   priceXL: "25.99",
  //   img: "https://pasibus.blob.core.windows.net/media/3353/cebulion-vegan_xl.png?width=500&mode=max&animationprocessmode=first",
  // },
];

export const wypasik = [
  {
    name: "Double Pasik",
    ingredients: [
      "Double smash burger",
      "pink saunce",
      "double cheddar",
      "iceberg lettuce",
      "pickled cucumber",
      "fresh tomato",
    ],
    priceXL: "13.99",
    img: "https://pasibus.blob.core.windows.net/media/4555/double-pasik-kiosk.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Cheese Pasik",
    ingredients: [
      "100% beef",
      "mayonnaise",
      "iceberg lettuce",
      "tomato-basil sauce",
      "cheddar cheese",
    ],
    priceXL: "13.99",
    img: "https://pasibus.blob.core.windows.net/media/3582/cheese-pasik.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Jalapeño Pasik",
    ingredients: [
      "100% beef",
      "jalapeño-mayo sauce",
      "iceberg lettuce",
      "jalapeño",
      "onion",
    ],
    priceXL: "13.99",
    hot: 2,
    img: "https://pasibus.blob.core.windows.net/media/3584/jalapeno-pasik.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //     name: 'Sweet Onion Pasik',
  //     ingredients: ['100% beef', 'mayonnaise', 'stewed onion',
  //         'cheddar cheese', 'BBQ sauce'],
  //     priceXL: '13.99',
  //     img:'https://pasibus.blob.core.windows.net/media/3585/sweet-onion-pasik.png?width=500&mode=max&animationprocessmode=first',
  // },
  {
    name: "BBQ Pasik",
    ingredients: [
      "100% beef",
      "mayonnaise",
      "iceberg lettuce",
      "cheddar cheese",
      "pickled cucumber",
      "BBQ sauce",
    ],
    priceXL: "13.99",
    img: "https://pasibus.blob.core.windows.net/media/3581/bbq-pasik.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const zestawy = [
  // {
  //     name: 'Cool Pasik',
  //     ingredients: ['Any Pasik', 'Pasi Fries'],
  //     priceXL: '16.99',
  //     img:'https://pasibus.blob.core.windows.net/media/3658/cool-zestaw-wypasik.png?width=500&mode=max&animationprocessmode=first',
  // },
  {
    name: "Full Pasik",
    ingredients: ["Any Pasik", "Pasi Fries", "Selected Drink"],
    priceXL: "21.99",
    img: "https://pasibus.blob.core.windows.net/media/3663/zestaw-full-wypasik.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Standard Set",
    ingredients: ["Standard", "Pasi Fries", "Selected Drink"],
    priceXL: "32.99",
    img: "https://pasibus.blob.core.windows.net/media/3861/standard-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Bebek Junior Set",
    ingredients: ["Bebek Junior", "Pasi Fries", "Selected Drink"],
    priceXL: "33.99",
    img: "https://pasibus.blob.core.windows.net/media/3855/bebek-junior-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Włoski Pastuch Set",
    ingredients: ["Włoski Pastuch", "Pasi Fries", "Selected Drink"],
    priceXL: "34.99",
    img: "https://pasibus.blob.core.windows.net/media/3859/pastuch-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Gonzales Set",
    ingredients: ["Gonzales", "Pasi Fries", "Selected Drink"],
    priceXL: "34.99",
    img: "https://pasibus.blob.core.windows.net/media/3858/gonzales-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "Red Hot Set",
  //   ingredients: ["Red Hot", "Pasi Fries", "Selected Drink"],
  //   priceXL: "33.99",
  //   img: "https://pasibus.blob.core.windows.net/media/3860/red-hot-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  // },
  {
    name: "Awokadus Set",
    ingredients: ["Awokadus", "Pasi Fries", "Selected Drink"],
    priceXL: "36.99",
    img: "https://pasibus.blob.core.windows.net/media/3860/red-hot-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Chorizard Set",
    ingredients: ["Chorizard", "Pasi Fries", "Selected Drink"],
    priceXL: "37.99",
    img: "https://pasibus.blob.core.windows.net/media/3857/chorizard-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Biały Wilk Set",
    ingredients: ["Biały Wilk", "Pasi Fries", "Selected Drink"],
    priceXL: "34.99",
    img: "https://pasibus.blob.core.windows.net/media/3856/bia%C5%82y-wilk-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const dodatki = [
  // {
  //     name: 'Cheese Fryta',
  //     description: ['Pasi fries, cheese icing, grilled bacon, jalapeño'],
  //     priceL: '28.99',
  //     priceXL: '14.99',
  //     fryty: true,
  //     img:"https://pasibus.blob.core.windows.net/media/4063/fryta.png?width=500&mode=max&animationprocessmode=first",
  // },
  // {
  //     name: 'Cheese Fryta Hot',
  //     description: ['Pasi fries, cheese icing, grilled bacon, fresh chives'],
  //     priceL: '28.99',
  //     priceXL: '14.99',
  //     fryty: true,
  //     hot:1,
  //     img:"https://pasibus.blob.core.windows.net/media/4062/fryta-hot.png?width=500&mode=max&animationprocessmode=first",
  // },
  {
    name: "Pasi Fries Large",
    description: ["Classic fries with peel!"],
    priceXL: "9.99",
    img: "https://pasibus.blob.core.windows.net/media/3376/frytki_pude%C5%82ko_3.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi Fries Medium",
    description: ["Classic fries with peel!"],
    priceXL: "7.99",
    img: "https://pasibus.blob.core.windows.net/media/3368/frytki_3.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi Fries Small",
    description: ["Classic fries with peel!"],
    priceXL: "5.99",
    img: "https://pasibus.blob.core.windows.net/media/3377/frytki_7.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi Sweet Potatoes",
    description: ["Thick, crunchy sweet potatoes."],
    priceXL: "10.99",
    img: "https://pasibus.blob.core.windows.net/media/3384/bataty_2.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Cheese Balls 8pcs.",
    description: ["Classic | Spicy | Mix. Served with our pink sauce."],
    priceXL: "19.99",
    img: "https://pasibus.blob.core.windows.net/media/3418/kulki-serowe_mix_8szt.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Cheese Balls 4pcs.",
    description: ["Classic | Spicy | Mix. Served with our pink sauce."],
    priceXL: "11.99",
    img: "https://pasibus.blob.core.windows.net/media/3416/kulki-serowe_mix_4szt.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi Nachos",
    description: ["Spicy, Served with our pink sauce."],
    priceXL: "7.99",
    img: "https://pasibus.blob.core.windows.net/media/3374/nachosy_pude%C5%82ko_3.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "greenies",
    description: ["Mix of salads with homemade dressing."],
    priceXL: "7.99",
    img: "https://pasibus.blob.core.windows.net/media/3372/zielsko_2.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Extra Sauce",
    description: ["One extra sauce: white, pink, BBQ, redhot, jalapeño mayo"],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3593/dip-r%C3%B3%C5%BCowy.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "3 sauce set",
    description: ["3 extra sauces: white, pink, BBQ, redhot, jalapeño mayo"],
    priceXL: "5,-",
    img: "https://pasibus.blob.core.windows.net/media/3661/zestaw-3-sos%C3%B3w.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const sniadanko = [
  {
    name: "Boczuś",
    ingredients: [
      "scrumbled eggs",
      "mayonnaise",
      "cheddar cheese",
      "grilled bacon",
      "grilled mushrooms",
      "chives",
    ],
    priceL: "12.99",
    priceXL: "20.99",
    img: "https://pasibus.blob.core.windows.net/media/4199/boczus.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Świeżak",
    ingredients: [
      "fried egg",
      "white sauce",
      "arugula",
      "fresh tomato",
      "chives",
    ],
    priceL: "9.99",
    priceXL: "17.99",
    img: "https://pasibus.blob.core.windows.net/media/4200/swiezak.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Boczuś Włap",
    ingredients: [
      "scrumbled eggs",
      "mayonnaise",
      "cheddar cheese",
      "grilled bacon",
      "grilled mushrooms",
      "chives",
    ],
    priceL: "11.99",
    priceXL: "19.99",
    img: "https://pasibus.blob.core.windows.net/media/4202/boczus-wlap.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Świeżak Włap",
    ingredients: [
      "scrumbled eggs",
      "pink sauce",
      "cheddar cheese",
      "iceberg lettuce",
      "tomato",
    ],
    priceL: "9.99",
    priceXL: "17.99",
    img: "https://pasibus.blob.core.windows.net/media/4201/swiezak-wlap.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const salatki = [
  {
    name: "chicken",
    ingredients: [
      "Crispy chicken",
      "mix of lettuces with vinaigrette",
      "cherry tomatoes",
      "long-ripening cheese",
      "roasted sunflower and pumpkin seeds",
      "honey-mustard sauce with nuts",
      "nachos",
    ],
    priceXL: "26,-",
    img: "https://pasibus.blob.core.windows.net/media/3650/top_sa%C5%82atka_kurczak.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "chicken",
    ingredients: [
      "Crispy chicken",
      "original caesar sauce",
      "roman salad",
      "grilled bacon",
      "grana padano cheese",
      "herb and garlic croutons",
      "chives",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6342/sa%C5%82atka_cezar.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "vege chicken",
  //   ingredients: [
  //     "vege chicken in original coating",
  //     "mix of lettuces with vinaigrette",
  //     "cherry tomatoes",
  //     "long-ripening cheese",
  //     "roasted sunflower and pumpkin seeds",
  //     "honey-mustard sauce with nuts",
  //     "nachos",
  //   ],
  //   priceXL: "29,-",
  //   img: "https://pasibus.blob.core.windows.net/media/3649/top_sa%C5%82atka_kurczak-wege.png?width=500&mode=max&animationprocessmode=first",
  // },
];

export const kawa = [
  {
    name: "Cappuccino",
    ingredients: [""],
    priceL: "8.99",
    img: "https://pasibus.blob.core.windows.net/media/3683/kawa-z-piank%C4%85-2.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Latte",
    ingredients: [""],
    priceL: "8.99",
    img: "https://pasibus.blob.core.windows.net/media/3686/kawa-z-piank%C4%85-1.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Espresso",
    ingredients: [""],
    priceL: "5.99",
    img: "https://pasibus.blob.core.windows.net/media/3710/espresso2.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Americano",
    ingredients: [""],
    priceL: "7.99",
    img: "https://pasibus.blob.core.windows.net/media/3681/kawa-czarna.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Flat White",
    ingredients: [""],
    priceL: "8.99",
    img: "https://pasibus.blob.core.windows.net/media/3680/kawa-z-piank%C4%85-3.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Tea",
    ingredients: [""],
    priceL: "7.99",
    img: "https://pasibus.blob.core.windows.net/media/3685/herbata.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const lemoIce = [
  // {
  //     name: "Ice Pasino",
  //     ingredients: ['iced coffe'],
  //     priceXL: '9.99',
  //     img:'https://pasibus.blob.core.windows.net/media/3779/ice-pasino-2.jpg?width=500&mode=max&animationprocessmode=first',
  // },
  // {
  //     name: "Ice Pasino Caramel",
  //     ingredients: ['iced coffe with caramel syroup'],
  //     priceXL: '11.99',
  //     img:'https://pasibus.blob.core.windows.net/media/3780/ice-pasino.jpg?width=500&mode=max&animationprocessmode=first',
  // },
  {
    name: "Pasi Lemonade",
    ingredients: [""],
    priceL: "7,-",
    priceXL: "10,-",
    img: "https://pasibus.blob.core.windows.net/media/3737/lemoniada-_-szklanka-du%C5%BCa.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi IceTea",
    ingredients: [""],
    priceL: "7,-",
    priceXL: "10,-",
    img: "https://pasibus.blob.core.windows.net/media/3635/icetea.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const napoje = [
  {
    name: "Coca-Cola",
    ingredients: [""],
    priceL: "7,-",
    img: "https://pasibus.blob.core.windows.net/media/3505/cola.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Coca-Cola Zero",
    ingredients: [""],
    priceL: "7,-",
    img: "https://pasibus.blob.core.windows.net/media/3496/cola-zero.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Fanta",
    ingredients: [""],
    priceL: "7,-",
    img: "https://pasibus.blob.core.windows.net/media/3492/fanta.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Sprite",
    ingredients: [""],
    priceL: "7,-",
    img: "https://pasibus.blob.core.windows.net/media/3500/sprite.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "Red Bull",
  //   ingredients: [""],
  //   priceL: "12,-",
  //   img: "https://pasibus.blob.core.windows.net/media/3596/red-bull.png?width=500&mode=max&animationprocessmode=first",
  // },
  // {
  //   name: "Red Bull Zero",
  //   ingredients: [""],
  //   priceL: "12,-",
  //   img: "https://pasibus.blob.core.windows.net/media/3595/red-bull-sugar-free.png?width=500&mode=max&animationprocessmode=first",
  // },
  {
    name: "Good Stuff",
    ingredients: ["pear, rhubarb, plum, mango, yerba mate, ginger, gooseberry"],
    priceL: "11,-",
    img: "https://pasibus.blob.core.windows.net/media/3774/dobry-materia%C5%82.jpg?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Sparkling Water",
    ingredients: [""],
    priceL: "6,-",
    img: "https://pasibus.blob.core.windows.net/media/3488/%C5%BCywiec-gaz.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Still Water",
    ingredients: [""],
    priceL: "6,-",
    img: "https://pasibus.blob.core.windows.net/media/3507/butelka-%C5%BCywiec.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Żywiec Naturals",
    ingredients: ["Flavored still water: lime-mint, raspberry-lemon"],
    priceL: "8,-",
    img: "https://pasibus.blob.core.windows.net/media/3509/butelka-%C5%BCywiec-cytryna-malina.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Żywiec Flavored",
    ingredients: ["Flavored sparkling water: lemon-mint, orange-grapefruit"],
    priceL: "8,-",
    img: "https://pasibus.blob.core.windows.net/media/3487/butelka-%C5%BCywiec-cytryna-mi%C4%99ta.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Cappy Juice",
    ingredients: ["apple, orange"],
    priceL: "8,-",
    img: "https://pasibus.blob.core.windows.net/media/3498/cappy-pomara%C5%84cza.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const boxy = [
  {
    name: "Big box",
    ingredients: ["3x Any pasik, plenty of fries"],
    priceL: "47.99",
    img: "https://pasibus.blob.core.windows.net/media/3666/du%C5%BCy-box.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Giga box",
    ingredients: ["6x Any pasik, lots of fries"],
    priceL: "95.99",
    img: "https://pasibus.blob.core.windows.net/media/3665/giga-box.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const doladowanie = [
  {
    name: "Beef",
    ingredients: [""],
    priceXL: "8,-",
    img: "https://pasibus.blob.core.windows.net/media/3558/kotlet-wo%C5%82owy-xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Chicken",
    ingredients: [""],
    priceXL: "8,-",
    img: "https://pasibus.blob.core.windows.net/media/3556/kurczak.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Vege",
    ingredients: [""],
    priceXL: "8,-",
    img: "https://pasibus.blob.core.windows.net/media/3567/kotlet-wege-xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Fried Cheese",
    ingredients: [""],
    priceXL: "8,-",
    img: "https://pasibus.blob.core.windows.net/media/3559/kotlet-serowy.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Bacon",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3776/boczek.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Cheddar Cheese",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3775/cheddar.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Chilli",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3555/chilli.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Jalapeño",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3570/jalapeno.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Fried Egg",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3571/jajko.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Grilled Muchrooms",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3557/pieczarki.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Chorizo",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3565/chorizo.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Nachos",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3561/nachosy.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "long-ripening cheese",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3586/parmezan.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "onion",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3562/cebula.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "pickled cucumber",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3560/og%C3%B3rek.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "tomato",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3563/pomidor.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Guacamole",
    ingredients: [""],
    priceXL: "5,-",
    img: "https://pasibus.blob.core.windows.net/media/3566/guacamole.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "white sauce",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3592/dip-bia%C5%82y.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "pink sauce",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3593/dip-r%C3%B3%C5%BCowy.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "iceberg lettuce",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3637/sa%C5%82ata-lodowa.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "arugula",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3638/rukola.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "lambs lettuce",
    ingredients: [""],
    priceXL: "2,-",
    img: "https://pasibus.blob.core.windows.net/media/3639/roszponka.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const wlapy = [
  {
    name: "Standard",
    ingredients: [
      "chicken in original coating",
      "cheddar cheese",
      "pink sauce",
      "iceberg lettuce",
      "fresh tomato",
    ],
    priceL: "28.99",
    priceXL: "14.99",
    img: "https://pasibus.blob.core.windows.net/media/3878/standart-w%C5%82ap-solo.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Gonzo",
    ingredients: [
      "chicken in original coating",
      "mayo-jalapeño sauce",
      "cheddar cheese",
      "crushed nachos",
      "jalapeño",
      "onion",
    ],
    priceL: "20.99",
    priceXL: "14.99",
    img: "https://pasibus.blob.core.windows.net/media/3874/gonzo-w%C5%82ap-solo.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "Bebek",
  //   ingredients: [
  //     "chicken in original coating",
  //     "cheddar cheese",
  //     "BBQ sauce",
  //     "stewed onion",
  //     "pickled cucumber",
  //   ],
  //   priceL: "21.99",
  //   priceXL: "14.99",
  //   img: "https://pasibus.blob.core.windows.net/media/3870/bebek-w%C5%82ap-solo.png?width=500&mode=max&animationprocessmode=first",
  // },
];

export const wlapson = [
  {
    name: "Włapson Pink",
    ingredients: [
      "chicken in original coating",
      "cheddar cheese",
      "pink sauce",
      "iceberg lettuce",
    ],
    priceL: "28.99",
    priceXL: "6.99",
    img: "https://pasibus.blob.core.windows.net/media/4050/w%C5%82apson-r%C3%B3%C5%BCowy-full-zestaw-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
];

// LTO 1 - MAC & CHEESE
// export const special = [
//   {
//     name: "MAC&CHEESE BURGER",
//     ingredients: [
//       "100% beef / chicken / vegetarian / cheese cutlet",
//       "pink sauce",
//       "grilled bacon",
//       "mimolette cheese",
//       "pickled red onion",
//       "chives",
//       "Mac&Cheese",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5887/lto1_mc_burger.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE PODWÓJNY BURGER",
//     ingredients: [
//       "100% beef / chicken / vegetarian / cheese cutlet",
//       "pink sauce",
//       "grilled bacon",
//       "mimolette cheese",
//       "pickled red onion",
//       "chives",
//       "Mac&Cheese",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5890/lto1_mc_burger_double.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE CHILI BURGER",
//     ingredients: [
//       "100% beef / chicken / vegetarian / cheese cutlet",
//       "pink sauce",
//       "grilled bacon",
//       "mimolette cheese",
//       "pickled red onion",
//       "chives",
//       "fried chili sprinkles",
//       "Mac&Cheese",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5891/lto1_mc_burger_chili.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE CHILI PODWÓJNY BURGER",
//     ingredients: [
//       "100% beef / chicken / vegetarian / cheese cutlet",
//       "pink sauce",
//       "grilled bacon",
//       "mimolette cheese",
//       "pickled red onion",
//       "chives",
//       "fried chili sprinkles",
//       "Mac&Cheese",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5889/lto1_mc_burger_double_chili.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE WŁAP",
//     ingredients: [
//       "Chicken in original coating",
//       "pink sauce",
//       "grilled bacon",
//       "pickled red onion",
//       "chives",
//       "Mac&Cheese",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5888/lto1_wlap.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE KUBEK",
//     ingredients: ["Maccaroni with cheese sauce", "black pepper", "chives"],
//     img: "https://pasibus.blob.core.windows.net/media/5892/lto1_kubelek.png?width=500&mode=max&animationprocessmode=first",
//   },
// ];

// LTO 2 - SZARPAK
// export const special = [
//   {
//     name: "PULLED BEEF BURGER",
//     ingredients: [
//       "100% beef / chicken / vegetarian / cheese cutlet",
//       "pulled beef with demi glace sauce",
//       "pickled red cabbage",
//       "double cheddar cheese",
//       "mayo-bbq sauce",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6219/pb-poj.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF PODWÓJNY BURGER",
//     ingredients: [
//       "100% beef / chicken / vegetarian / cheese cutlet",
//       "pulled beef with demi glace sauce",
//       "pickled red cabbage",
//       "double cheddar cheese",
//       "mayo-bbq sauce",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6218/pb-podw.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF PIKANTNY BURGER",
//     ingredients: [
//       "100% beef / chicken / vegetarian / cheese cutlet",
//       "pulled beef with demi glace sauce",
//       "pickled red cabbage",
//       "double cheddar cheese",
//       "mayo-bbq sauce",
//       "jalapeno",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6217/pb-pik-poj.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF PIKANTNY PODWÓJNY BURGER",
//     ingredients: [
//       "100% beef / chicken / vegetarian / cheese cutlet",
//       "pulled beef with demi glace sauce",
//       "pickled red cabbage",
//       "double cheddar cheese",
//       "mayo-bbq sauce",
//       "jalapeno",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6216/pb-pik-podw.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF WŁAP",
//     ingredients: [
//       "100% pulled beef with demi glace sauce",
//       "pickled red cabbage",
//       "double cheddar cheese",
//       "mayo-bbq sauce",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6220/pb-wlap.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF FRYTKI",
//     ingredients: [
//       "Fries",
//       "100% pulled beef with demi glace sauce",
//       "mayo-bbq sauce",
//       "chives",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6215/pb-frytki.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF FRYTKI PIKANTNE",
//     ingredients: [
//       "Fries",
//       "100% pulled beef with demi glace sauce",
//       "mayo-bbq sauce",
//       "chives",
//       "jalapeno",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6214/pb-frytki-pik.png?width=500&mode=max&animationprocessmode=first",
//   },
// ];

// LTO 3 - CHILI CON CARNE
export const special = [
  {
    name: "AMIGO BURGER",
    ingredients: [
      "100% beef | chicken | vege | cheese cutlet",
      "sour cream",
      "Chili con carne",
      "nachos",
      "cilantro",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6613/amigo.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "AMIGO BURGER PODWÓJNY",
    ingredients: [
      "100% beef | chicken | vege | cheese cutlet",
      "sour cream",
      "Chili con carne",
      "nachos",
      "cilantro",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6605/amigo-podwo-jny.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "MACHO BURGER",
    ingredients: [
      "100% beef | chicken | vege | cheese cutlet",
      "sour cream",
      "Chili con carne",
      "cucumber and mango salsa",
      "nachos",
      "guacamole",
      "cilantro",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6612/macho.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "PODWÓJNY MACHO BURGER",
    ingredients: [
      "100% beef | chicken | vege | cheese cutlet",
      "sour cream",
      "Chili con carne",
      "cucumber and mango salsa",
      "nachos",
      "guacamole",
      "cilantro",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6611/macho-podwo-jny.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "CHICA WŁAP",
    ingredients: [
      "Chili con carne",
      "lettuce",
      "sour cream",
      "cheddar cheese",
      "nachos",
      "cilantro",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6607/chica-w%C5%82ap.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "NACHOS CON CARNE",
    ingredients: [
      "Chili con carne",
      "nachos",
      "sour cream",
      "roasted padron peppers",
      "cucumber and mango salsa",
      "cilantro",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6608/nachos-con-carne.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "MANGO LEMO",
    ingredients: ["Mango lemonade"],
    img: "https://pasibus.blob.core.windows.net/media/6609/mango-lemo.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "MANGO CHILI LEMO",
    ingredients: ["Mango and chili lemonade"],
    img: "https://pasibus.blob.core.windows.net/media/6610/mango-lemo-chili.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "SALSA OGÓREK Z MANGO",
    ingredients: ["Cucumber and mango salsa with cilantro"],
    img: "https://pasibus.blob.core.windows.net/media/6606/salsa-ogo-rek-mango.png?width=500&mode=max&animationprocessmode=first",
  },
];
