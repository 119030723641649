import styled from "styled-components"
import { sniadanko } from "../../data";
import SniadankoListItem from "./SniadankoListItem";
import do12 from "../../images/do12.svg"
import sniadanie from "../../images/sniadanie.png"

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:0 15px;
padding-bottom:10px;
background-color: #f0f0f0;
`
const Image = styled.img`
width:100%;
padding:15px;
padding-bottom:0;
margin-bottom:8px;
`
const Jumbotron = styled.img`
width:100%;
border-radius: 5px;
-webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
-moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
margin-bottom:20px;
margin-top:15px;
`

const SniadankoList = () => {
    return (
        <Wrapper>
            <Image src={do12}/>
            {/* <Jumbotron src={sniadanie}/> */}
            {sniadanko.map(pasik=><SniadankoListItem burger={pasik} key={pasik.name}/>)}
        </Wrapper>
    );
}

export default SniadankoList;