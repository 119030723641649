import styled from "styled-components"
import Multiline from "../Multiline/Multiline"
import pepper from "../../images/pepper.svg";
import mniejszy from "../../images/mniejszy.svg";
import wiekszy from "../../images/zestaw.svg";

const Wrapper = styled.li`
display:flex;
flex-direction: row;
border-radius:5px;
padding:10px;
gap:5px;
background-color:white;
-webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
-moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
`
const Image = styled.img`
width:120px;
height:120px;
`

const ColumnWrapper = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
`
const RowWrapper = styled.span`
display:flex;
flex-direction: row;
align-items: center;
`

const HotIcon = styled.img`
height:1.7rem;
margin-left:1px;
&:first-child{
    margin-left:10px;
}
`;

const RollIcon = styled.img`
height:${props=>props.bigger?"2rem" : "1.5rem"};
margin-right:3px;
`;

const Ingredients = styled.p`
font-size:1.2rem;
padding:5px 3px;
`;

const Bold = styled.strong`
font-weight: 900;
`;

const PriceWrapper = styled.span`
display:inline-flex;
align-items: center;
font-family:'Gotham', sans-serif;
font-size: 1.6rem;
padding-left:5px;
`;

const Price = styled.span`
display:inline;
align-items: center;
font-family:'Gotham', sans-serif;

font-size: 2rem;
`;

const SmallPrice = styled.span`
display:inline;
align-items: center;
font-family:'Gotham', sans-serif;

font-size: 1.8rem;
`;

const Separator = styled.div`
display:inline-block;
width:1px;
height:2rem;
background-color: black;
margin: 0 8px;
`;

const PriceSup = styled.sup`
display:inline;
align-items: center;
font-family:'Gotham', sans-serif;

font-size: 1.2rem;
margin-bottom: 10px;
`;


const SmallPriceSup = styled.sup`
display:inline;
align-items: center;
font-family:'Gotham', sans-serif;

font-size: 1.2rem;
margin-bottom: 10px;
`;


const BoxyListItem = (props) => {
    return (
        <Wrapper>
            <Image src={props.burger.img}/>
            <ColumnWrapper>
            <RowWrapper><Multiline>{props.burger.name}</Multiline>
            {props.burger.hot?
        [...Array(props.burger.hot)].map((e,i)=><HotIcon src={pepper} key={i}/>)
        : false
        }</RowWrapper>
        <Ingredients>
{props.burger.ingredients[0]}
</Ingredients>
{/* <PriceWrapper><RollIcon bigger src={wiekszy}/><Price>{props.burger.priceL.split('.')[0]}</Price><PriceSup>{props.burger.priceL.split('.')[1]}</PriceSup></PriceWrapper> */}

            </ColumnWrapper>
        </Wrapper>
    );
}

export default BoxyListItem;