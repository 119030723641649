import styled from "styled-components"
import { napoje } from "../../data";
import NapojeListItem from "./NapojeListItem";

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:0 15px;
padding-bottom:10px;
background-color: #f0f0f0;

`

const NapojeList = () => {
    return (
        <Wrapper>
            {napoje.map(pasik=><NapojeListItem burger={pasik} key={pasik.name}/>)}
        </Wrapper>
    );
}

export default NapojeList;