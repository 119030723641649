import styled from "styled-components"
import { wypasik } from "../../data";
import WypasikListItem from "./WypasikListItem";
import podmienWypasik from "../../images/podmien-wypasik.svg"
import fullzestaw from "../../images/fullzestaw.png"

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:0 15px;
padding-bottom:15px;
background-color: #f0f0f0;
`
const Image = styled.img`
width:100%;
padding:15px;
`
const Jumbotron = styled.img`
width:100%;
border-radius: 5px;
-webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
-moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
margin-bottom:20px;
`
const HyperLink = styled.a`
outline:0;
border:0;
text-decoration:none ;
`;

const WypasikList = () => {
    return (
        <Wrapper>
            <Image src={podmienWypasik}/>
            <HyperLink href="/zestawy"><Jumbotron src={fullzestaw}/></HyperLink>
            {wypasik.map(pasik=><WypasikListItem burger={pasik} key={pasik.name}/>)}
        </Wrapper>
    );
}

export default WypasikList;