import styled from "styled-components"
import { zestawy } from "../../data";
import ZestawyListItem from "./ZestawyListItem";

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:15px;
background-color: #f0f0f0;
`

const ZestawyList = () => {
    return (
        <Wrapper>
            {zestawy.map(zestaw=><ZestawyListItem burger={zestaw} key={zestaw.name}/>)}
        </Wrapper>
    );
}

export default ZestawyList;