
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import WypasikList from "./WypasikList"
import BannerLink from "../BannerLink/BannerLink";
import Footer from "../Footer/Footer";

const Wypasik = () => {
    return (
        <>
        <Heading>wypasik</Heading>
        <MenuScroll highlight="wypasik"/>
        <BannerLink link="/dodatki">+ add extras</BannerLink>
        <WypasikList/>
        <Footer/>
        </>
    );
};

export default Wypasik;