import styled from "styled-components"
import { boxy } from "../../data";
import BoxyListItem from "./BoxyListItem";
import podmienbox from "../../images/podmienbox.svg"
import boxyimage from "../../images/boxy.jpg"

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:0 15px;
padding-bottom:10px;
background-color: #f0f0f0;
`
const Image = styled.img`
width:100%;
padding:15px;
padding-bottom:0;
margin-bottom:-8px;
`
const Jumbotron = styled.img`
width:100%;
border-radius: 5px;
-webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
-moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
margin-bottom:20px;
margin-top:15px;
`

const BoxyList = () => {
    return (
        <Wrapper>
            <Image src={podmienbox}/>
            <Jumbotron src={boxyimage}/>
            {boxy.map(pasik=><BoxyListItem burger={pasik} key={pasik.name}/>)}
        </Wrapper>
    );
}

export default BoxyList;