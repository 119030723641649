
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import DodatkiList from "./DodatkiList";
import BannerLink from "../BannerLink/BannerLink";
import Footer from "../Footer/Footer";

const Dodatki = () => {
    return (
        <>
        <Heading>extras</Heading>
        <MenuScroll highlight="dodatki"/>
        <BannerLink link="/burgery">back to burgers</BannerLink>
        <DodatkiList/>
        <Footer/>
        </>
    );
};

export default Dodatki;